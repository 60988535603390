import { default as axios } from "axios";
import { MONTHLY_PNL_API_URL } from "../api.routes";
import { getAuthToken } from "../auth/AuthContext";

export async function getMonthlyPNL(startDate = null, endDate = null) {
  const params = {};
  if (startDate) {
    params.start_date = startDate;
  }
  if (endDate) {
    params.end_date = endDate;
  }

  const res = await axios.get(MONTHLY_PNL_API_URL, {
    params: params,
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}
