const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_API_URL = BASE_URL + "api/";

// User API URLs
export const LOGIN_API_URL = BASE_URL + "token";
export const SIGNUP_API_URL = BASE_API_URL + "users";
export const ACTIVATE_USER_API_URL = SIGNUP_API_URL + "/activate";
export const USERS_API_URL = BASE_API_URL + "users";
export const USER_INFO_API_URL = USERS_API_URL + "/me";

// Change Password API URLs
export const FORGET_PASSWORD_API_URL = BASE_API_URL + "forget-password";
export const RESET_PASSWORD_API_URL = BASE_API_URL + "reset-password";

// Symbols API URLs
export const SYMBOLS_API_URL = BASE_API_URL + "symbols";

// Monthly PNL API URLs
export const MONTHLY_PNL_API_URL = BASE_API_URL + "monthly-pnl";

// Orders API URLs
export const ORDERS_API_URL = BASE_API_URL + "orders";

// Positions API URLs
export const POSITIONS_API_URL = BASE_API_URL + "positions";

// Trade API URLs
export const TRADE_API_URL = BASE_API_URL + "trade";
export const ATTACH_TP_ORDER_API_URL = BASE_API_URL + "attach_tp";
export const ATTACH_SL_ORDER_API_URL = BASE_API_URL + "attach_sl";
export const TRAIL_SL_ORDER_API_URL = BASE_API_URL + "trail_sl";

// Websocket URLs
export const WS_UPDATES_WEBSOCKET_URL = BASE_API_URL + "ws-updates/";
export const LTP_WEBSOCKET_URL = BASE_API_URL + "ltp/";
