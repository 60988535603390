import { default as axios } from "axios";
import { TRADE_API_URL } from "../api.routes";
import { getAuthToken } from "../auth/AuthContext";

export async function triggerTrade(data) {
  const res = await axios.post(TRADE_API_URL, data, {
    headers: {
      Authorization: getAuthToken(),
    },
  });
  return res;
}

export async function getMonthlyTrades(startDate = null, endDate = null) {
  const params = {};
  if (startDate) {
    params.start_date = startDate;
  }
  if (endDate) {
    params.end_date = endDate;
  }

  const res = await axios.get(TRADE_API_URL, {
    headers: {
      Authorization: getAuthToken(),
    },
    params,
  });

  return res;
}
