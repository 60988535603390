import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";

import { Box, Button, Stack } from "@mui/material";

import { CustomToast } from "../components/CustomToast";
import EntryOrderSection from "../components/EntryOrderSection";
import OrdersTableSection from "../components/OrdersTableSection";
import PositionsTableSection from "../components/PositionsTableSection";

import { LTP_WEBSOCKET_URL, WS_UPDATES_WEBSOCKET_URL } from "../api.routes";

const HomePage = () => {
  const { userInfo } = useAuth();
  const navigate = useNavigate();

  const [symbol, setSymbol] = useState(null);
  const [ltp, setLTP] = useState(null);

  const [walletBalance, setWalletBalance] = useState(null);
  const [isWalletBalanceLoading, setIsWalletBalanceLoading] = useState(true);

  const [isOrdersLoading, setIsOrdersLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  const [isPositionsLoading, setIsPositionsLoading] = useState(true);
  const [positions, setPositions] = useState([]);

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    return `${dateTime.getFullYear()}-${("0" + (dateTime.getMonth() + 1)).slice(
      -2
    )}-${("0" + dateTime.getDate()).slice(-2)} ${(
      "0" + dateTime.getHours()
    ).slice(-2)}:${("0" + dateTime.getMinutes()).slice(-2)}:${(
      "0" + dateTime.getSeconds()
    ).slice(-2)}`;
  };

  const handleSymbolChange = (newSymbol) => {
    setSymbol(newSymbol);
  };

  useEffect(() => {
    const socket = new WebSocket(WS_UPDATES_WEBSOCKET_URL);

    socket.onopen = () => {
      setIsOrdersLoading(true);
      setIsPositionsLoading(true);
      setIsWalletBalanceLoading(true);
      CustomToast({
        message: "WebSocket connected",
        type: "success",
      });
    };

    socket.onmessage = (event) => {
      const webhooksData = JSON.parse(event.data);
      if (webhooksData.success) {
        if (webhooksData.positions) {
          const formattedPositions = webhooksData.positions.map((position) => ({
            ...position,
            entry_order_time: formatDateTime(position.entry_order_time),
          }));
          setIsPositionsLoading(false);
          setPositions(formattedPositions);
        } else if (webhooksData.orders) {
          const formattedOrders = webhooksData.orders.map((order) => ({
            ...order,
            datetime: formatDateTime(order.datetime),
          }));

          setOrders(formattedOrders);
          setIsOrdersLoading(false);
          setOrders(formattedOrders);
        } else if (webhooksData.walletBalance) {
          setIsWalletBalanceLoading(false);
          setWalletBalance(webhooksData.walletBalance);
        }
      } else {
        CustomToast({
          message: webhooksData.msg,
          type: "error",
        });
      }
    };

    socket.onerror = (error) => {
      setIsOrdersLoading(true);
      setIsPositionsLoading(true);
      setIsWalletBalanceLoading(true);
      CustomToast({
        message: "WebSocket not connected",
        type: "error",
      });
    };

    const cleanup = () => {
      socket.close(1000);
    };

    // Close the WebSocket connection when the component is unmounted or when the user navigates away
    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    const socket = new WebSocket(LTP_WEBSOCKET_URL);

    if (symbol === null) {
      setLTP(null);
      socket.close(1000);
      return;
    }
    socket.onopen = () => {
      socket.send(JSON.stringify({ symbol: symbol }));
      CustomToast({
        message: `${symbol} LTP websocket connected`,
        type: "success",
      });
    };

    socket.onmessage = (event) => {
      const webhooksData = JSON.parse(event.data);
      if (webhooksData.success) {
        if (symbol in webhooksData) {
          setLTP(webhooksData[symbol]);
        }
      } else {
        CustomToast({
          message: webhooksData.msg,
          type: "error",
        });
      }
    };

    socket.onerror = (error) => {
      setLTP(null);
      CustomToast({
        message: `${symbol} LTP WebSocket not connected`,
        type: "error",
      });
    };

    const cleanup = () => {
      socket.close(1000);
    };

    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        gap: "20px",
      }}
    >
      <Stack direction="row" columnGap={3} justifyContent="end">
        {userInfo && userInfo.role === "ADMIN" && (
          <Button
            variant="contained"
            color="success"
            sx={{
              width: "fit-content",
              fontWeight: 600,
            }}
            onClick={() => {
              navigate("/admin");
            }}
          >
            Go to admin page
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontWeight: 600,
            width: "fit-content",
          }}
          onClick={() => navigate("/monthly-trade-info")}
        >
          Go to Monthly Trade Page
        </Button>
      </Stack>
      <EntryOrderSection
        ltp={ltp}
        walletBalance={walletBalance}
        isWalletBalanceLoading={isWalletBalanceLoading}
        onSymbolChange={handleSymbolChange}
      />
      <OrdersTableSection isOrdersLoading={isOrdersLoading} orders={orders} />
      <PositionsTableSection
        isPositionsLoading={isPositionsLoading}
        positions={positions}
      />
    </Box>
  );
};

export default HomePage;
