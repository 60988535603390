import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";

import {
  Button,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { getMonthlyPNL } from "../services/monthly_pnl.service";
import { getMonthlyTrades } from "../services/trade.service";

import CustomCard from "../components/CustomCard";
import { CustomToast } from "../components/CustomToast";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid #ccc",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ffffff",
  },
}));

const MonthlyTradeInfoPage = () => {
  const { userInfo, logout } = useAuth();
  const navigate = useNavigate();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [monthlyPnLDataLoaded, setMonthlyPnLDataLoaded] = useState(false);
  const [monthlyTradesDataLoaded, setMonthlyTradesDataLoaded] = useState(false);

  const [selectedMonthYear, setSelectedMonthYear] = useState();
  const [monthlyPnL, setMonthlyPnL] = useState([]);
  const [selectedMonthTrades, setSelectedMonthTrades] = useState([]);
  const [pnlMetrics, setPnlMetrics] = useState({
    totalPnlUSD: 0,
    winRatePercentage: 0,
    avgWinPercentage: 0,
  });

  const [pnlSortOrder, setPnlSortOrder] = useState("none"); // "none", "asc", "desc"
  const [pnlOrderByColumn, setPnlOrderByColumn] = useState(null);

  const [tradeSortOrder, setTradeSortOrder] = useState("none"); // "none", "asc", "desc"
  const [tradeOrderByColumn, setTradeOrderByColumn] = useState(null);

  const initialDateFormData = {
    start_date: null,
    end_date: null,
  };

  const {
    control: controlDateForm,
    handleSubmit: handleSubmitDateForm,
    setError: setErrorDateForm,
    setValue: setValueDateFrom,
    reset: resetDateForm,
  } = useForm({
    defaultValues: {
      ...initialDateFormData,
    },
  });

  const onSubmitDateForm = (data) => {
    setFormSubmitted(true);

    let startDate = data["start_date"];
    let endDate = data["end_date"];

    if (
      startDate &&
      endDate &&
      startDate.format("YYYY-MM-DD") !== endDate.format("YYYY-MM-DD")
    ) {
      if (!startDate.isBefore(endDate)) {
        setErrorDateForm("start_date", {
          type: "required",
          message: "Start Date must be earlier than the End Date.",
        });
        setErrorDateForm("end_date", {
          type: "required",
          message: "End Date must be later than the Start Date.",
        });
        setFormSubmitted(false);
        return;
      }
    }

    if (startDate) {
      startDate = startDate.format("YYYY-MM-DD");
    }
    if (endDate) {
      endDate = endDate.format("YYYY-MM-DD");
    }
    fetchMonthlyPnl(startDate, endDate);
    fetchMonthlyTrades(startDate, endDate);

    setFormSubmitted(false);
  };

  const disableDatesTodayOnwards = (date) => {
    return date > moment();
  };
  const disableYearsFromThisYearOnwards = (year) => {
    return year.format("YYYY") > moment().format("YYYY");
  };

  // Function to calculate metrics from trade data
  const calculateMetrics = (trades) => {
    let totalPnlUSD = 0;
    let totalTrades = trades.length;
    let winningTrades = 0;
    let totalPercentage = 0;

    trades.forEach((trade) => {
      const pnlUSD = trade.pnl_usd || 0;
      const pnlPercentage = trade.pnl_percentage || 0;

      totalPnlUSD += pnlUSD;
      totalPercentage += pnlPercentage;

      if (pnlUSD > 0) {
        winningTrades++;
      }
    });

    const winRatePercentage = (winningTrades / totalTrades) * 100 || 0;
    const avgWinPercentage = totalPercentage / totalTrades || 0;

    setPnlMetrics({
      totalPnlUSD,
      winRatePercentage,
      avgWinPercentage,
    });
  };

  useEffect(() => {
    fetchMonthlyPnl();
    fetchMonthlyTrades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMonthlyPnl = (startDate, endDate) => {
    setMonthlyPnLDataLoaded(false);
    getMonthlyPNL(startDate, endDate)
      .then(({ data }) => {
        const updatedPnLData = data.monthly_pnl_data.map((yearData) => {
          const newData = { ...yearData };
          for (let month = 1; month <= 12; month++) {
            if (!newData.hasOwnProperty(month)) {
              newData[month] = undefined; // Initialize to undefined if missing
            }
          }
          return newData;
        });
        setMonthlyPnL(updatedPnLData);
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          CustomToast({
            message: response.data.detail,
            type: "error",
          });
          logout();
        } else {
          CustomToast({
            message: response.data.msg || response.data.detail || response.data,
            type: "error",
          });
        }
        setMonthlyPnLDataLoaded(false);
      })
      .finally(() => setMonthlyPnLDataLoaded(true));
  };

  const fetchMonthlyTrades = (startDate, endDate) => {
    setMonthlyTradesDataLoaded(false);
    getMonthlyTrades(startDate, endDate)
      .then(({ data }) => {
        const trades = data.trades;
        setSelectedMonthTrades(trades);
        setSelectedMonthYear(
          !startDate && !endDate
            ? "All Trades"
            : `${moment(startDate).format("Do MMMM YYYY")} to ${moment(
                endDate
              ).format("Do MMMM YYYY")}`
        );
        calculateMetrics(trades);
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          CustomToast({
            message: response.data.detail,
            type: "error",
          });
          logout();
        } else {
          CustomToast({
            message: response.data.msg || response.data.detail || response.data,
            type: "error",
          });
        }
      })
      .finally(() => setMonthlyTradesDataLoaded(true));
  };

  const handleMonthClick = (year, month) => {
    setSelectedMonthYear(`${months[month - 1]}-${year}`);

    const formattedMonth = month < 10 ? `0${month}` : month; // Ensure two-digit month
    let startDate = moment(
      `${year}-${formattedMonth}-01`,
      "YYYY-MM-DD",
      true
    ).startOf("month"); // Start of the month
    let endDate = moment(startDate, "YYYY-MM-DD", true).endOf("month"); // End of the month

    setValueDateFrom("start_date", startDate);
    setValueDateFrom("end_date", endDate);

    startDate = startDate.format("YYYY-MM-DD");
    endDate = endDate.format("YYYY-MM-DD");

    fetchMonthlyPnl(startDate, endDate);
    fetchMonthlyTrades(startDate, endDate);
  };

  const handleRequestSort = (event, property) => {
    // Cycle through states: none -> asc -> desc -> none
    let newOrder = "none";
    if (pnlSortOrder === "none") {
      newOrder = "asc";
    } else if (pnlSortOrder === "asc") {
      newOrder = "desc";
    }

    setPnlSortOrder(newOrder);
    setPnlOrderByColumn(newOrder === "none" ? null : property);
  };

  const handleTradeRequestSort = (event) => {
    // Cycle through states: none -> asc -> desc -> none
    let newOrder = "none";
    if (tradeSortOrder === "none") {
      newOrder = "asc";
    } else if (tradeSortOrder === "asc") {
      newOrder = "desc";
    }

    setTradeSortOrder(newOrder);
    setTradeOrderByColumn(newOrder === "none" ? null : "pnl");
  };

  const sortedMonthlyPnL = [...monthlyPnL].sort((a, b) => {
    const key =
      pnlOrderByColumn === "ytd"
        ? pnlOrderByColumn
        : months.indexOf(pnlOrderByColumn) + 1;
    if (pnlSortOrder === "asc") {
      return a[key] !== b[key] ? (a[key] < b[key] ? -1 : 1) : 0; // No sorting if same
    } else if (pnlSortOrder === "desc") {
      return a[key] !== b[key] ? (a[key] < b[key] ? 1 : -1) : 0; // No sorting if same
    }
    return 0; // No sorting
  });

  const sortedTrades = [...selectedMonthTrades].sort((a, b) => {
    if (tradeSortOrder === "asc") {
      return a[tradeOrderByColumn] !== b[tradeOrderByColumn]
        ? a[tradeOrderByColumn] < b[tradeOrderByColumn]
          ? -1
          : 1
        : 0; // No sorting if same
    } else if (tradeSortOrder === "desc") {
      return a[tradeOrderByColumn] !== b[tradeOrderByColumn]
        ? a[tradeOrderByColumn] < b[tradeOrderByColumn]
          ? 1
          : -1
        : 0; // No sorting if same
    }
    return 0; // No sorting
  });

  const handleResetPageClick = () => {
    resetDateForm();
    fetchMonthlyPnl();
    fetchMonthlyTrades();
  };

  return (
    <Stack direction="column" margin={5} rowGap={5}>
      <Stack direction="row" columnGap={3} justifyContent="end">
        <Button
          variant="contained"
          color="success"
          sx={{
            width: "fit-content",
            fontWeight: 600,
          }}
          onClick={() => {
            navigate("/home");
          }}
        >
          Go to home
        </Button>
        {userInfo && userInfo.role === "ADMIN" && (
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: "fit-content",
              fontWeight: 600,
            }}
            onClick={() => {
              navigate("/admin");
            }}
          >
            Go to admin page
          </Button>
        )}
        <Button
          variant="contained"
          color="error"
          sx={{
            fontWeight: 600,
          }}
          onClick={() => {
            logout();
          }}
        >
          Log out
        </Button>
      </Stack>
      <form onSubmit={handleSubmitDateForm(onSubmitDateForm)}>
        <Stack
          direction="row"
          columnGap={10}
          paddingBlock={3}
          paddingInline={2}
          justifyContent="center"
          alignItems="center"
          backgroundColor="white"
          borderRadius={2}
        >
          <>
            <Controller
              name="start_date"
              control={controlDateForm}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  label="Start Date"
                  format="YYYY-MM-DD"
                  shouldDisableDate={disableDatesTodayOnwards}
                  shouldDisableYear={disableYearsFromThisYearOnwards}
                  slotProps={{
                    field: { clearable: true },
                    textField: {
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
          </>
          <>
            <Controller
              name="end_date"
              control={controlDateForm}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  label="End Date"
                  format="YYYY-MM-DD"
                  shouldDisableDate={disableDatesTodayOnwards}
                  shouldDisableYear={disableYearsFromThisYearOnwards}
                  slotProps={{
                    field: { clearable: true },
                    textField: {
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
          </>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formSubmitted}
            sx={{
              fontWeight: 600,
              cursor: formSubmitted ? "not-allowed" : "pointer",
            }}
          >
            Submit
          </Button>
          <Button
            type="button"
            variant="contained"
            color="error"
            disabled={formSubmitted}
            sx={{
              fontWeight: 600,
              cursor: formSubmitted ? "not-allowed" : "pointer",
            }}
            onClick={handleResetPageClick}
          >
            Reset Page
          </Button>
        </Stack>
      </form>
      {monthlyPnLDataLoaded && sortedMonthlyPnL && (
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            sx={{ border: "1px solid #ccc" }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell sx={{ fontWeight: 600 }}></StyledTableCell>
                {months.map((month, _) => (
                  <StyledTableCell
                    sx={{ fontWeight: 600 }}
                    key={month}
                    align="right"
                  >
                    <TableSortLabel
                      active={pnlOrderByColumn === month}
                      direction={
                        pnlOrderByColumn === month
                          ? pnlSortOrder === "asc"
                            ? "asc"
                            : "desc"
                          : "asc"
                      }
                      onClick={(event) => handleRequestSort(event, month)}
                    >
                      {month}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
                <StyledTableCell sx={{ fontWeight: 600 }} align="right">
                  <TableSortLabel
                    active={pnlOrderByColumn === "ytd"}
                    direction={
                      pnlOrderByColumn === "ytd"
                        ? pnlSortOrder === "asc"
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    onClick={(event) => handleRequestSort(event, "ytd")}
                  >
                    YTD
                  </TableSortLabel>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {sortedMonthlyPnL.length > 0 ? (
                sortedMonthlyPnL.map((row) => (
                  <StyledTableRow key={row.year}>
                    <StyledTableCell sx={{ fontWeight: 600 }}>
                      {row.year}
                    </StyledTableCell>
                    {months.map((_, index) => {
                      const pnlValue = row[index + 1];

                      return (
                        <StyledTableCell
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleMonthClick(row.year, index + 1)}
                          sx={{
                            backgroundColor:
                              pnlValue === undefined ||
                              pnlValue === null ||
                              pnlValue === 0
                                ? null
                                : pnlValue > 0
                                ? "green"
                                : "darkred",
                            color:
                              pnlValue === undefined ||
                              pnlValue === null ||
                              pnlValue === 0
                                ? null
                                : "white",
                          }}
                        >
                          {pnlValue === undefined || pnlValue === null
                            ? pnlValue
                            : pnlValue.toFixed(2)}
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell
                      sx={{
                        backgroundColor:
                          row.ytd === undefined ||
                          row.ytd === null ||
                          row.ytd === 0
                            ? null
                            : row.ytd > 0
                            ? "green"
                            : "darkred",
                        color:
                          row.ytd === undefined ||
                          row.ytd === null ||
                          row.ytd === 0
                            ? null
                            : "white",
                      }}
                    >
                      {row.ytd === undefined || row.ytd === null
                        ? row.ytd
                        : row.ytd.toFixed(2)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    style={{ textAlign: "center", letterSpacing: "1.5px" }}
                    colSpan={15}
                  >
                    No pnl data found.
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {monthlyTradesDataLoaded && pnlMetrics && (
        <Stack
          direction="row"
          width="100%"
          columnGap={20}
          justifyContent="center"
        >
          <Paper
            elevation={2}
            sx={{ minWidth: "150px", paddingBlock: 2, paddingInline: 3 }}
          >
            <Stack direction="column" rowGap={2}>
              <span>Total P/L:</span>
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "25px",
                  color:
                    pnlMetrics.totalPnlUSD.toFixed(2) === "0.00"
                      ? null
                      : pnlMetrics.totalPnlUSD > 0
                      ? "green"
                      : "darkred",
                }}
              >
                {pnlMetrics.totalPnlUSD.toFixed(2)}
              </span>
            </Stack>
          </Paper>
          <Paper
            elevation={2}
            sx={{ minWidth: "150px", paddingBlock: 2, paddingInline: 3 }}
          >
            <Stack direction="column" rowGap={2}>
              <span>Win Rate:</span>
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "25px",
                  color:
                    pnlMetrics.winRatePercentage.toFixed(2) === "0.00"
                      ? null
                      : pnlMetrics.winRatePercentage > 0
                      ? "green"
                      : "darkred",
                }}
              >
                {pnlMetrics.winRatePercentage.toFixed(2)}%
              </span>
            </Stack>
          </Paper>
          <Paper
            elevation={2}
            sx={{ minWidth: "150px", paddingBlock: 2, paddingInline: 3 }}
          >
            <Stack direction="column" rowGap={2}>
              <span>Average Win %:</span>
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "25px",
                  color:
                    pnlMetrics.avgWinPercentage.toFixed(2) === "0.00"
                      ? null
                      : pnlMetrics.avgWinPercentage > 0
                      ? "green"
                      : "darkred",
                }}
              >
                {pnlMetrics.avgWinPercentage.toFixed(2)}%
              </span>
            </Stack>
          </Paper>
        </Stack>
      )}

      {monthlyTradesDataLoaded && selectedMonthYear && selectedMonthTrades && (
        <CustomCard cardTitle={`${selectedMonthYear}`}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            sx={{ border: "1px solid #ccc" }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell sx={{ fontWeight: 600 }}>
                  Symbol
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 600 }}>Side</StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 600 }}>
                  Quantity
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 600 }}>
                  Entry Time
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 600 }}>
                  Exit Time
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 600 }}>
                  Entry Price
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 600 }}>
                  Exit Price
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 600 }}>
                  <TableSortLabel
                    active={tradeOrderByColumn === "pnl"}
                    direction={
                      tradeSortOrder === "asc"
                        ? "asc"
                        : tradeSortOrder === "desc"
                        ? "desc"
                        : "asc"
                    }
                    onClick={(event) => handleTradeRequestSort(event)}
                  >
                    P/L
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 600 }}>
                  P/L (%)
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {sortedTrades.length > 0 ? (
                sortedTrades.map((trade) => (
                  <StyledTableRow key={trade.trade_id}>
                    <StyledTableCell>{trade.symbol}</StyledTableCell>
                    <StyledTableCell>{trade.side}</StyledTableCell>
                    <StyledTableCell>{trade.qty}</StyledTableCell>
                    <StyledTableCell>{trade.entry_order_time}</StyledTableCell>
                    <StyledTableCell>{trade.exit_order_time}</StyledTableCell>
                    <StyledTableCell>
                      {trade.entry_order_filled_price === undefined ||
                      trade.entry_order_filled_price === null
                        ? trade.entry_order_filled_price
                        : trade.entry_order_filled_price.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {trade.exit_order_avg_price === undefined ||
                      trade.exit_order_avg_price === null
                        ? trade.exit_order_avg_price
                        : trade.exit_order_avg_price.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        backgroundColor:
                          trade.pnl_usd === undefined ||
                          trade.pnl_usd === null ||
                          trade.pnl_usd === 0
                            ? null
                            : trade.pnl_usd > 0
                            ? "green"
                            : "darkred",
                        color:
                          trade.pnl_usd === undefined ||
                          trade.pnl_usd === null ||
                          trade.pnl_usd === 0
                            ? null
                            : "white",
                      }}
                    >
                      {trade.pnl_usd === undefined || trade.pnl_usd === null
                        ? trade.pnl_usd
                        : trade.pnl_usd.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        backgroundColor:
                          trade.pnl_percentage === undefined ||
                          trade.pnl_percentage === null ||
                          trade.pnl_percentage === 0
                            ? null
                            : trade.pnl_percentage > 0
                            ? "green"
                            : "darkred",
                        color:
                          trade.pnl_percentage === undefined ||
                          trade.pnl_percentage === null ||
                          trade.pnl_percentage === 0
                            ? null
                            : "white",
                      }}
                    >
                      {trade.pnl_percentage === undefined ||
                      trade.pnl_percentage === null
                        ? trade.pnl_percentage
                        : trade.pnl_percentage.toFixed(2) + "%"}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    style={{ textAlign: "center", letterSpacing: "1.5px" }}
                    colSpan={9}
                  >
                    No trades found.
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </CustomCard>
      )}
    </Stack>
  );
};

export default MonthlyTradeInfoPage;
